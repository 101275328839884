import React from 'react';
import './project.css'; // Importing the CSS file for styling

const Project = () => {
  return (
    <>
      <div className="project-container">
        <div className="scrolling-text">
          <h2>Data Under Feed</h2>
        </div>
      </div>
    </>
  );
};

export default Project;